export class Club {
    // String name
    // [String] leads
    // [String] teachers
    // [String] emails
    // String description
    // String categories

    constructor(name, leads, teachers, emails, description, categories) {
      this.name = name;
      this.leads = leads;
      this.teachers = teachers;
      this.emails = emails;
      this.description = description;
      this.categories = categories;
    }
  }

